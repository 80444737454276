import * as React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import PageHelmet from "../../components/common/helmet";

// const css = require("./index.module.css");

interface Song {
  title: string;
  url: string;
}

async function fetchSongs() {
  const res = await fetch("https://daussho.my.id/public/wedding-song.json");
  const data = await res.json();
  return data;
}

const SongPage: React.FC = () => {
  const [accompanySong, setAccompanySong] = React.useState<Song[]>([]);
  const [weddingSong, setWeddingSong] = React.useState<Song[]>([]);

  React.useEffect(() => {
    fetchSongs().then((data) => {
      setAccompanySong(data.accompany);
      setWeddingSong(data.song);
    });
  }, []);

  return (
    <>
      <PageHelmet title="Wedding Songs" />

      <Container className="my-3">
        <h1>Lagu Pengiring</h1>
        <ol
          style={{
            marginBottom: 20,
          }}
        >
          {accompanySong.map((song) => (
            <h5>
              <li
                style={{
                  marginBottom: 10,
                }}
              >
                {song.title}
                <div>
                  <iframe
                    // width="400"
                    // height="215"
                    src={song.url}
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </li>
            </h5>
          ))}
        </ol>

        <h1>Lagu</h1>
        <ol>
          {weddingSong.map((song) => (
            <h5>
              <li
                style={{
                  marginBottom: 10,
                }}
              >
                {song.title}
                <div>
                  <iframe
                    src={song.url}
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </li>
            </h5>
          ))}
        </ol>
      </Container>
    </>
  );
};

export default SongPage;
